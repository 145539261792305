import { useLeaflet } from "react-leaflet";
import L from "leaflet";
import { useEffect } from "react";
// import { getColor } from "./utils";

const Legend = () => {
  const { map } = useLeaflet();
 

  useEffect(() => {
    const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      const grades = [
          "No rain",
          "Rain",
          "No flood",
         "Flood",
         "Storm"
        ],
        labels = [
          require("./icons/norain.png"),
          require("./icons/heavy-rain.png"),

          require("./icons/nullico.png"),
          require("./icons/blue.png"),
          require("./icons/thunder.png"),
        ];

      for (let i = 0; i < grades.length; i++) {
        div.innerHTML +=
          grades[i] +
          (" <img src=" + labels[i] + " height='25' width='25'>") +
          "<br>";
      }

      return div;
    };

    legend.addTo(map);
  });
  return null;
};

export default Legend;
