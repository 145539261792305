/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { FeatureGroup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";

import "leaflet.heat";

import "leaflet.markercluster";
import axios from "axios";

import flood from "./icons/blue.png";

const floodIcon = new L.Icon({
  iconUrl: flood,
  iconSize: [40, 40],
});


export default function GeojsonLayerTwitter({ url, cluster }) {
 
  const mapRef = useRef();
  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    axios
      .get(
        "https://cors-anywhere.herokuapp.com/http://173.249.20.52/api/tweet/?api_key=efc0e944e0f37d883d79832a8d270a47e61a2718&username=spark&created_at=2%20hour%20ago%7Cnow&format=json&country=India&size=5000&limit=5000"
      )
      .then((res) => {
        let coords = [];
        let final=[];
        // console.log(res.data.features[0].geometry.coordinates.reverse())
        for(let i = 0; i < res.data.features.length; i++) {
        coords.push(res.data.features[i].geometry.coordinates.reverse());
        
        }
     
        const mapGeoJson = new L.GeoJSON(res.data, {
          pointToLayer: (feature = {}, latlng) => {
            const { properties = {} } = feature;
            const { geometry = {} } = feature;
            let { description } = properties;
            const { admin_region } = properties;
            const { flood_probability } = properties;
            
           

            if (flood_probability > 0) {
              return L.marker(geometry.coordinates, {
                icon: floodIcon,
              });
            }
          
          },

          onEachFeature: (feature = {}, layer) => {
            const { properties = {} } = feature;
            const { geometry = {} } = feature;

            const { place } = properties;
            let { admin_region} = properties;

            const { flood_probability } = properties;

            const {user_created_at} = properties;
            const { coordinates } = geometry;

            layer.bindPopup(
              `<b style=""color:black">State- ${admin_region}</b> <br><br><b style=""color:black">City- ${place}</b><br> <br><b style=""color:black">Level- ${flood_probability}</b><br> <br> <b style=""color:black">Time- ${user_created_at}</b><br> <br> <b style=""color:black"> lat ->${geometry.coordinates[1]}<br> <br>lon ->${geometry.coordinates[0]}  </b>`
            );
          },
        });

        mapGeoJson.addTo(map);
        var heat = L.heatLayer(coords,{
        
          radius : 25,
          blur : 9,
          maxZoom:12
        }).addTo(map);
        
      });
  }, [mapRef]);

  var GroupComponent = cluster ? MarkerClusterGroup : FeatureGroup;
  return (
    <GroupComponent ref={mapRef}>
     
    </GroupComponent>
  );
}
