/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { FeatureGroup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L, { heatLayer } from "leaflet";
import "leaflet.heat";
import "leaflet.markercluster";
import axios from "axios";
import Legend from "./Legend";

import norain from "./icons/norain.png";
import flood from "./icons/blue.png";

import storm from "./icons/thunder.png";
import rain from "./icons/heavy-rain.png";

import nullicon from "./icons/nullico.png";

const rainIcon = new L.Icon({
  iconUrl: rain,
  iconSize: [40, 40],
});

const norainIcon = new L.Icon({
  iconUrl: norain,
  iconSize: [36, 36],
});
const floodIcon = new L.Icon({
  iconUrl: flood,
  iconSize: [40, 40],
});
const nullIcon = new L.Icon({
  iconUrl: nullicon,
  iconSize: [40, 40],
});
const thunderIcon = new L.Icon({
  iconUrl: storm,
  iconSize: [40, 40],
});

export default function GeojsonLayer({ url, cluster }) {

  const mapRef = useRef();
  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    axios.get("https://indrareport.herokuapp.com/api/").then((res) => {
      let coords = [];
      // console.log(res.data)
      for(let i = 0; i < res.data.features.length; i++) {
      coords.push(res.data.features[i].geometry.coordinates);
      }
      const mapGeoJson = new L.GeoJSON(res.data, {
    

        pointToLayer: (feature = {}, latlng) => {
          const { properties = {} } = feature;
          const { geometry = {} } = feature;
          let { description } = properties;
          const { category } = properties;
          if (description === "No Rain" && category === "Rain") {
            return L.marker(geometry.coordinates, {
              icon: norainIcon,
            });
          }
          if (description === "NONE" && category === "Flood") {
            return L.marker(geometry.coordinates, {
              icon: nullIcon,
            });
          }
          if( category === "Flood") {

              return L.marker(geometry.coordinates, {
                  icon: floodIcon
              });
           }
           if( category === "Rain") {

            return L.marker(geometry.coordinates, {
                icon: rainIcon
            });
         }
         if( category === "Lightning") {

          return L.marker(geometry.coordinates, {
              icon: thunderIcon
          });
       }
        },
        
        onEachFeature: (feature = {}, layer) => {
          let coords = [];
          const { properties = {} } = feature;
          const { geometry = {} } = feature;

          const { category } = properties;
          let { description } = properties;

          const { obsval } = properties;

          const { obstime } = properties;
          const { coordinates } = geometry;
          layer.bindPopup(
            `<b style=""color:black">Type- ${category}</b> <br><br><b style=""color:black">Description- ${description}</b><br> <br><b style=""color:black">Level- ${obsval}</b><br> <br> <b style=""color:black">Time- ${obstime}</b><br> <br> <b style=""color:black"> lat ->${geometry.coordinates[0]}<br> <br>lon ->${geometry.coordinates[1]}  </b>`
          );

        },
        
      });
  

      mapGeoJson.addTo(map);
      var heat = L.heatLayer(coords,{
        
        radius : 25,
        blur : 9,
        maxZoom:12
      }).addTo(map);
    });
  }, [mapRef]);

  var GroupComponent = cluster ? MarkerClusterGroup : FeatureGroup;
  return (
    <GroupComponent ref={mapRef}>
      <Legend />
    </GroupComponent>
  );
}
