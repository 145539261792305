/* eslint-disable react/jsx-no-target-blank */
import React from "react";
// react plugin for creating charts
import Control from "react-leaflet-control";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

import BugReport from "@material-ui/icons/Navigation";

import "leaflet.heat";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import iit from "assets/img/iitd.png";
import "./map.css";

import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
// eslint-disable-next-line no-unused-vars
import { GestureHandling } from "leaflet-gesture-handling";

import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

import L from "leaflet";
import gplay from "../../assets/img/gplau.png";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import ind from "../../assets/img/ind.png";
import GeojsonLayer from "./GeojsonLayerFunc";

// import GeojsonLayerTwitter from "./GeojsonLayerFuncTwitter";
import { Map, TileLayer, LayersControl } from "react-leaflet";
import GeojsonLayerTwitter from "./GeojsonLayerFuncTwitter";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const { BaseLayer } = LayersControl;

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div className="mainbody">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <h4 className={classes.cardTitle}>
              Project INDRA (International Natural Disasters Research and
              Analytics)
            </h4>
            <p className={classes.cardCategory}>
              Crowdsourcing natural disaster reports.
            </p>
            <CustomTabs
              headerColor="rose"
              tabs={[
                {
                  tabName: "INDRA Reporter App",
                  tableHead: "sat",
                  tabIcon: BugReport,
                  tabContent: (
                    <Map
                      className="mapp"
                      useFlyTo={true}
                      center={[28.7, 77.1]}
                      zoom={4}
                      doubleClickZoom={false}
                      zoomSnap={0.5}
                      minZoom={1}
                      zoomControl={false}
                    >
                  
                      <LayersControl position="bottomleft">
                        <BaseLayer checked name="OpenStreetMap" color="black">
                          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        </BaseLayer>
                        <BaseLayer name="NASA">
                          <TileLayer url="https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=VAhWdaVAbTv5n9K7uVpX" />
                        </BaseLayer>
                        <BaseLayer name="Black">
                          <TileLayer url="https://api.maptiler.com/maps/toner/{z}/{x}/{y}.png?key=VAhWdaVAbTv5n9K7uVpX" />
                        </BaseLayer>
                        <BaseLayer name="Tone">
                          <TileLayer url="https://api.maptiler.com/maps/topographique/{z}/{x}/{y}.png?key=VAhWdaVAbTv5n9K7uVpX" />
                        </BaseLayer>
                      </LayersControl>

                      {<GeojsonLayer url="" cluster={true} />}
                      <Control position="topleft">
                        <img className="logoo" src={iit} alt="" />
                        <img className="logoo" src={ind} alt="" />
                      </Control>
                      <Control position="topleft"></Control>

                      <Control postion="topright">
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=com.indra&hl=en_US"
                        >
                          <img className="gplay" src={gplay} alt="" />
                        </a>
                      </Control>
                    </Map>
                  ),
                },
                {
                  tabName: " Twitter Flood Sensor (India)",
                  tabIcon: BugReport,
                  tabContent: (
                    <Map
                      className="mapp"
                      useFlyTo={true}
                      center={[28.7, 77.1]}
                      zoom={4}
                      doubleClickZoom={false}
                      zoomSnap={0.5}
                      minZoom={1}
                      zoomControl={false}
                      
                    >
                      <LayersControl position="bottomleft">
                        <BaseLayer checked name="OpenStreetMap" color="black">
                          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        </BaseLayer>
                        <BaseLayer name="NASA">
                          <TileLayer url="https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=VAhWdaVAbTv5n9K7uVpX" />
                        </BaseLayer>
                        <BaseLayer name="Black">
                          <TileLayer url="https://api.maptiler.com/maps/toner/{z}/{x}/{y}.png?key=VAhWdaVAbTv5n9K7uVpX" />
                        </BaseLayer>
                        <BaseLayer name="Tone">
                          <TileLayer url="https://api.maptiler.com/maps/topographique/{z}/{x}/{y}.png?key=VAhWdaVAbTv5n9K7uVpX" />
                        </BaseLayer>
                      </LayersControl>

                      {<GeojsonLayerTwitter url="" cluster={true} />}
                      <Control position="topleft">
                        <img className="logoo" src={iit} alt="" />
                        <img className="logoo" src={ind} alt="" />
                      </Control>
                      <Control position="topleft"></Control>

                      <Control postion="topright">
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=com.indra&hl=en_US"
                        >
                          <img className="gplay" src={gplay} alt="" />
                        </a>
                      </Control>
                    </Map>
                  ),
                },
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
      {/* <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Sales</h4>
              <p className={classes.cardCategory}>
                
                increase in today sales.
              </p>
            </CardBody>
          </Card>
        </GridItem> */}

      {/* <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Used Space</p>
              <h3 className={classes.cardTitle}>
                49/50 <small>GB</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Get more space
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Revenue</p>
              <h3 className={classes.cardTitle}>$34,245</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Fixed Issues</p>
              <h3 className={classes.cardTitle}>75</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Tracked from Github
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Followers</p>
              <h3 className={classes.cardTitle}>+245</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer> */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Team</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["ID", "Name", "Affiliation"]}
                tableData={[
                  ["1", "Dr. Manabendra Saharia", "Assistant Prof. IIT Delhi"],

                  ["2", "Dr. Milan Kalas", "KAJO, s.r.o. Bytca, Slovakia"],
                  ["3", "Dhiraj saharia", "B.Tech. Student Tezpur University"],
                  ["4", "Avish Jain", "B.Tech. Student IIT Delhi"],
                  ["5", "Satyakam Singhal", "B.Tech. Student IIT Delhi"],
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
