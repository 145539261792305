import Dashboard from "@material-ui/icons/Dashboard";
import DashboardPage from "views/Dashboard/Dashboard.js";
const dashboardRoutes = [
  {
    path: "",
    name: "Dashboard",
    heading:
      "Project INDRA (International Natural Disasters Research and Analytics)",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/",
  },
];

export default dashboardRoutes;
